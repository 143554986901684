<script lang="ts">
import { defineAsyncComponent } from 'vue';

const debounce = (func: Function, wait: number) => {
  let timeout: number;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export default {
  components: {
    RegisterComponent: defineAsyncComponent(() => import('@/components/Auth/RegisterComponent.vue')),
    LoginComponent: defineAsyncComponent(() => import('@/components/Auth/LoginComponent.vue')),
    ForgotComponent: defineAsyncComponent(() => import('@/components/Auth/ForgotComponent.vue')),
    LoggedComponent: defineAsyncComponent(() => import('@/components/Auth/LoggedComponent.vue')),
    ResetComponent: defineAsyncComponent(() => import('@/components/Auth/ResetComponent.vue')),
    SearchComponent: defineAsyncComponent(() => import('@/components/Common/SearchComponent.vue')),
    PopupComponent: defineAsyncComponent(() => import('@/components/Popup/PopupComponent.vue')),
    ApprovalComponent: defineAsyncComponent(() => import('@/components/Popup/ApprovalComponent.vue')),
   // DonatePromoComponent: defineAsyncComponent(() => import('@/components/Popup/DonatePromoComponent.vue'))
  },
  data() {
    return {
      dropdown: null as null | 'login' | 'register' | 'forgot' | 'logged' | 'reset',
      query: '',
      debounceQuery: (...args: any[]) => {},
      isSearchOpen: false,
      isApprovalVisible: false,
      resetToken: '',
      //isDonatePromoVisible: false
    }
  },
  computed: {
    user() {
      return this.$user.value?.username
    },
  },
  methods: {
    sleep(ms: number) {
      return new Promise((resolve: TimerHandler) => setTimeout(resolve, ms))
    },
    openDropdown() {
      if (this.dropdown === 'login') {
        this.closeDropdown()
      } else {
        this.switchDropdown('login')
      }
    },
    async switchDropdown(page: null | 'login' | 'register' | 'forgot' | 'logged' | 'reset') {
      await this.sleep(1)
      this.dropdown = page
    },
    closeDropdown() {
      this.dropdown = null
    },
    async openSearch() {
      await this.sleep(1)
      this.isSearchOpen = true
      document.getElementById('search')?.focus()
    },
    async openApproval() {
      await this.sleep(1)
      this.isApprovalVisible = true
    },
    closeApproval() {
      this.isApprovalVisible = false
    },
    //closeDonatePromo() {
    //  this.isDonatePromoVisible = false
    //},
    closeSearch() {
      this.isSearchOpen = false
    },
    searchQuery(e: Event) {
      this.openSearch()
      const target = e.target as HTMLInputElement
      let value = target.value
      value = value.trim().replace(/\s+/g, ' ')

      this.debounceQuery(value)
    },

    /*
    async loadStripeLibrary() {
      if (!this.stripe) {
        const stripe = await import('@stripe/stripe-js').then((module) => module.loadStripe)
        this.stripe = await stripe('pk_live_51PlDNzDA52p0c0lXDvySflVux1GnFIHtFmGQXVyI42nrDJCkEUjkhxI9XNgQ04dvLlsDkqODJhUJHSjEqm9CF1O600tYQpQaa9')
      }
    },
    async handlePayment(amount: number) {
      await this.loadStripeLibrary()

      if (this.stripe) {
        const { error } = await this.stripe.redirectToCheckout({
          lineItems: [{ price: this.getPriceIdForAmount(amount), quantity: 1 }],
          mode: 'payment',
          successUrl: 'https://mozisarok.hu/success?session_id={CHECKOUT_SESSION_ID}',
          cancelUrl: 'https://mozisarok.hu/?msg=cancel'
        })

        if (error) {
          console.error('Stripe checkout error:', error)
        }
      }
    },

    getPriceIdForAmount(amount: number): string {
      switch (amount) {
        case 1500:
          return 'price_1PlDxGDA52p0c0lXqwW5MjxC'
        //return 'price_1PnOzeDA52p0c0lXnvNKpfHC';
        case 3000:
          return 'price_1PnOffDA52p0c0lXpL2WMYqb'
        case 5000:
          return 'price_1PnOfyDA52p0c0lXitITSGpQ'
        case 10000:
          return 'price_1PnOgHDA52p0c0lXOxIVWzMp'
        case 50000:
          return 'price_1PnOgXDA52p0c0lXi9vsuiYY'
        default:
          throw new Error('Unknown amount')
      }
    },

    submitPayment() {
      const checkoutRef = this.$refs.checkoutRef
      checkoutRef.redirectToCheckout()
    },
    */
  },
  //created() {
  //  if (!localStorage.getItem('hasVisited')) {
  //    this.isDonatePromoVisible = true;
  //    localStorage.setItem('hasVisited', 'true');
  //  }
  //},
  watch: {
    '$route.query.reset-token'(val) {
      if(val) {
        this.resetToken = val
        this.openDropdown()
        this.switchDropdown('reset')
      }
    }
  },
  mounted() {
    this.debounceQuery = debounce((q: string) => this.query = q, 300)
    this.$emitter.on('openlogin', () => this.openDropdown())
  }
}
</script>

<template>
  <Transition name="opacity">
    <PopupComponent v-if="isApprovalVisible" @close="closeApproval">
      <ApprovalComponent></ApprovalComponent>
    </PopupComponent>
  </Transition>
  <!--<Transition name="opacity">
    <PopupComponent v-if="isDonatePromoVisible" @close="closeDonatePromo">
      <DonatePromoComponent></DonatePromoComponent>
    </PopupComponent>
  </Transition>-->

  <nav class="header noselect">
    <div class="left" @keydown.esc="closeSearch">
      <img alt="Kereső" class="search" src="/img/icons/magnifying-glass.svg" @click="openSearch" width="32"/>
      <input id="search" @focus="openSearch" @click="openSearch" @input="searchQuery" tabindex="0" placeholder="Kereső" height="30" />
      <Transition name="dropdown">
        <SearchComponent v-if="query" v-show="isSearchOpen" :query="query" @close="closeSearch" class="fromleft" />
      </Transition>
    </div>

    <div class="center" draggable="false">
      <router-link to="/" class="header-logo" tabindex="0" draggable="false">
        <img alt="Mozisarok" class="logo" :src="`/img/logos/logo-${$currentHoliday ? $currentHoliday + '-' : ''}w64.avif`" draggable="false" width="40" />
      </router-link>
    </div>

    <div class="right">
      <div v-if="!user" class="dropdown-toggle" @click="openDropdown()" @keydown.enter="openDropdown()" tabindex="0">
        <span>Belépés</span>
        <img class="caret nologin" src="/img/icons/login.svg" alt="Tovább" width="32" />
      </div>
      <a v-else class="dropdown-toggle" @click="!dropdown ? switchDropdown('logged') : {}" @keydown.enter="switchDropdown('logged')" tabindex="0">
        <img class="caret" :style="{ transform: dropdown ? 'rotate(180deg)' : '' }" src="/img/icons/caret-down.svg" width="32" />
        <img class="pfp" :src="`${$api}/profile/pfp?username=${user}`" width="30" />
      </a>
      <Transition name="dropdown">
        <ResetComponent v-if="dropdown === 'reset'" @login="switchDropdown('login')" @close="closeDropdown" :token="resetToken"></ResetComponent>
        <LoginComponent v-else-if="dropdown === 'login'" @register="switchDropdown('register')" @forgot="switchDropdown('forgot')" @close="closeDropdown"></LoginComponent>
        <RegisterComponent v-else-if="dropdown === 'register'" @login="switchDropdown('login')" @close="closeDropdown"></RegisterComponent>
        <ForgotComponent v-else-if="dropdown === 'forgot'" @login="switchDropdown('login')" @close="closeDropdown"></ForgotComponent>
        <LoggedComponent v-else-if="dropdown === 'logged'" @close="closeDropdown" @approval="openApproval"></LoggedComponent>
      </Transition>
    </div>

    <div class="bg"></div>
  </nav>
</template>

<style scoped>
.header {
  font-size: 32px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-basis: auto;
  flex-grow: 1;
  padding: 0 min(64px, 10vw);
  width: 100vw;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 64px;
    background: var(--bg);
    z-index: -1;
    box-shadow: 0 3.2px 15px var(--bg);
  }

  & .left,
  .center,
  .right,
  .bg {
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .left {
    justify-content: left;
    max-width: 100%;

    input {
      min-width: 0;
      flex-shrink: 1;
      width: fit-content;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 6px;
      border-radius: 4px;
      font-size: 16px;
    }

    .search {
      flex-grow: 0;
      margin-right: 8px;
      box-sizing: content-box;
      cursor: pointer;

      height: 19.2px;
    }
  }

  .center {
    overflow: visible;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 56px;

    @media (max-width: 300px) {
      display: none;
    }

    .header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      .logo {
        height: 40px;
      }
    }
  }

  .right {
    justify-content: right;
    width: 100%;

    .dropdown-toggle {
      width: max-content;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 16px;
      font-weight: 400;
      gap: 8px;
      cursor: pointer;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pfp {
        border-radius: 6px;
        height: 30px;
        flex-shrink: 0;
      }

      .caret {
        height: 19.2px;
        transition: transform 0.2s ease-in-out;
        flex-shrink: 0;
      }
    }
  }
}

@keyframes donate {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;

  overflow: hidden;
  transform-origin: top right;
  
  &.fromleft {
    transform-origin: top left;
  }
}

.dropdown-enter-from,
.dropdown-leave-to {
  transform: scale(100%, 0);
  opacity: 0;
}

.dropdown-enter-to,
.dropdown-leave-from {
  transform: scale(100%, 100%);
  opacity: 1;
}

@media (max-width: 720px) {
  .dropdown-enter-from,
  .dropdown-leave-to {
    transform: scale(0, 100%);
    opacity: 0;
  }
}

.opacity-enter-active,
.opacity-leave-active {
  transition: opacity 0.2s ease-out;

  overflow: hidden;
}

.opacity-enter-from,
.opacity-leave-to {
  opacity: 0;
}

.opacity-enter-to,
.opacity-leave-from {
  opacity: 1;
}
</style>
